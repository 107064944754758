import { TranslationsSchema } from './types';

export default {
  name: 'globalError',
  title: 'Global Error',
  sanityId: 'globalError',
  fields: {
    error_box_title: "Sorry, we've encountered a problem.",
    error_box_message:
      "While we work on fixing things, you can refresh the page to see if that helps. If that doesn't work, please try again later.",
    error_box_more_details: 'More details',
    error_box_reload_page_button: 'Reload page',
    error_404:
      'Something went wrong and we didn’t find the page you were looking for.\n' +
      'Try going back to the previous page or navigating to our home page.',
    error_return: 'RETURN TO HOME PAGE',
    error_500: '500 - Internal Server Error.',
    error_404_title: 'Oops... page not found',

    access_denied: 'Access Denied',
    you_dont_have_permission_to_view_this_page:
      'You don’t have permission to view this page. Contact an administrator to get access.',
    go_to_overview_page: 'Go to Overview Page',
  },
} satisfies TranslationsSchema;
