import { TranslationsSchema } from './types';

export default {
  name: 'approvalsPrOverview',
  title: 'Approvals PR Overview',
  sanityId: 'approvalsPrOverview',
  fields: {
    page_title: 'PURCHASE REQUISITIONS APPROVALS OVERVIEW',
    supplier: 'Supplier:',
    view_in_ariba: 'VIEW IN ARIBA',
    group_approvals_Ariba:
      'Group Approvals that need PR re-assignment from the queue can be completed only in Ariba.',
    complete_action_Ariba: 'Please complete this action in Ariba if this applies to you.',
    no_data: 'no data',
    details_amount: 'Amount',
    details_number_of_lines: 'Number of Lines',
    details_date_assigned: 'Date Assigned',
    details_PR_requester: 'PR Requester',
  },
} satisfies TranslationsSchema;
