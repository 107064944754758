import { TranslationsSchema } from './types';

export default {
  name: 'globalHeader',
  title: 'Global Header',
  sanityId: 'globalHeader',
  fields: {
    nav_category_status: 'Status',
    nav_link_status_pr: 'Purchase Requisitions',
    nav_link_status_po: 'Purchase Orders',
    nav_link_status_cr: 'Contract Requests',
    nav_link_status_invoice: 'Invoice Status',
    nav_category_approvals: 'Approvals',
    nav_link_approvals_pr: 'Purchase Requisitions',
    nav_link_approvals_invoice: 'Invoice Approvals',
  },
} satisfies TranslationsSchema;
