import { TranslationsSchema } from './types';

export default {
  name: 'statusPrDetails',
  title: 'Status PR Details',
  sanityId: 'statusPrDetails',
  fields: {
    pr_number: 'PR Number',
    amount: 'Amount',
    requisition_date: 'Requisition Date',
    status: 'Status',
    status_definitions: 'Status Definitions',
    requester: 'Requester',
    preparer: 'Preparer',
    purchasing_org: 'Purchasing Org',
    company_code: 'Company Code',
    pr_items: 'PR Items',
    pr_line: 'PR Line',
    supplier_id: 'SUPPLIER ID',
    pr_information: 'PR Information',
    pr_type: 'PR Type',
    pr_amount: 'Amount',
    unit_price: 'Unit Price',
    quantity: 'Quantity',
    unit_of_measure: 'Unit of Measure',
    po_number: 'PO number',
    account_information: 'Account Information',
    asset_number: 'Asset Number',
    internal_order: 'Internal Order',
    billing_cost_center: 'Billing (Cost Center)',
    gl_account: 'GL Account',
    split_accounting: 'Split Accounting',
    yes: 'Yes',
    view_in_arriba: 'View in Ariba',
    no: 'No',
    dates: 'Dates',
    need_by: 'Need by',
    service_start: 'Service Start',
    service_end: 'Service End',
    bottom_view_in_arriba: 'VIEW IN ARIBA',
    no_data: 'no data',
  },
} satisfies TranslationsSchema;
