import { TranslationsSchema } from './types';

export default {
  name: 'statusContractDetails',
  title: 'Status Contract Details',
  sanityId: 'statusContractDetails',
  fields: {
    title_pattern: 'Contract Request {requestId}',
    no_data: 'no data',

    notification_pattern:
      'Status is updated every 12 hours. To view the most up-to-date information, please visit {link}.',
    notification_link_text: 'Axxerion LINC',

    aging_in_days: 'Request submitted {days} days ago',
    project_title: 'Project Title',
    category: 'Category',
    subcategory: 'Subcategory',
    total_fee: 'Total Fee',
    view_in_linc: 'View in Linc',

    contract_request_details: 'Contract Request Details',

    supplier_id: 'Supplier ID',
    pr_number: 'PR Number',
    po_number: 'PO Number',
    cost_center: 'Cost Center',
    start_effective_date: 'Start/Effective Date',
    end_date: 'End Date',

    request_status: 'Request Status',
    status_definition: 'Status Definitions',
    requester: 'Requester',
    request_submission_date: 'Request Submission Date',
    business_owner: 'Business Owner',
    business_unit: 'Business Unit',

    assigned_legal_reviewer: 'Assigned Legal Reviewer',
    contract_type: 'Contract Type',
    contract_status: 'Contract Status',
    contract_id: 'Contract ID',
    docusign_cc_name: 'DocuSign CC Name',
    docusign_cc_email: 'DocuSign CC Email',

    signatory: 'Signatory',
    outside_party: 'Outside Party',
    outside_party_signatory_person: 'Outside Party Signatory Person',
    outside_party_signatory_email: 'Outside Party Signatory Email',
  },
} satisfies TranslationsSchema;
