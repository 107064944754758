import { TranslationsSchema } from './types';

export default {
  name: 'globalFilters',
  title: 'Global Filters',
  sanityId: 'globalFilters',
  fields: {
    today: 'Today',
    days: 'Days',
    to: 'to',
    date: 'dd.mm.yyyy',
    show_less: 'Show less',
    show_more: 'Show more',
    not_selected: 'not selected',
    min: 'min',
    max: 'max',
    go: 'go',
  },
} satisfies TranslationsSchema;
