import { TranslationsSchema } from './types';

export default {
  name: 'custom404PRDetails',
  title: 'Status PR Details 404',
  sanityId: 'custom404PRDetails',
  fields: {
    title: 'Oops... PR not found',
    message:
      'Something went wrong and we didn’t find the PR you were looking for. The record may be available in the source system.',
    ariba_button: 'GO TO ARIBA',
    return_button: 'RETURN TO HOME PAGE',
  },
} satisfies TranslationsSchema;
