import { TranslationsSchema } from './types';

export default {
  name: 'statusContactOverview',
  title: 'Status Contract Overview',
  sanityId: 'statusContactOverview',
  fields: {
    page_title: 'Contract Requests Overview',
    request_submitted: 'Request Submitted',
    refiners_request_submitted: 'Request Submitted',
    refiners_request_status: 'Request Status',
    refiners_contract_type: 'Contract Type',
    refiners_contract_status: 'Contract Status',
    refiners_requestor: 'Requester',
    refiners_business_owner: 'Business Owner',
    refiners_outside_party: 'Outside Party',
    request_status: 'Request Status',
    contract_type: 'Contract Type',
    contract_status: 'Contract Status',
    requestor: 'Requester',
    business_owner: 'Business Owner',
    outside_party: 'Outside Party',
    category: 'Category:',
    total_fee: 'Total Fee:',
    submission_date: 'Submission Date',
    status_definition: 'Status definitions',
    notification:
      'Status is updated every 12 hours. To view the most up-to-date information, please visit Axxerion LINC.',
  },
} satisfies TranslationsSchema;
