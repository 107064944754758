import { TranslationsSchema } from './types';

export default {
  name: 'loginPage',
  title: 'Login Page',
  sanityId: 'loginPage',
  fields: {
    login_popup_header: 'Sign in to proceed',
    login_popup_message:
      'Please note that you must have an active Takeda SSO user account to access Buyeasy.',
    login_button: 'Sign in',
    login_progress: 'Signing in...',
  },
} satisfies TranslationsSchema;
