import { TranslationsSchema } from './types';

export default {
  name: 'approvalsPrDetails',
  title: 'Approvals PR Details',
  sanityId: 'approvalsPrDetails',
  fields: {
    page_title: 'PR Approvals Detail',
    no_data: 'no data',

    title_pattern: '{PrNumber}',

    pr_number: 'PR Number',
    total_value: 'Total Value',
    company_code: 'Company Code',
    purchasing_org: 'Purchasing Org',
    requisition_date: 'Requisition Date',
    requester: 'Requester',
    preparer: 'Preparer',
    view_in_ariba: 'View in Ariba',

    description: 'Description',
    line_item: 'Line Item',
    supplier: 'Supplier',
    id: 'ID',

    pr_information: 'PR Information',
    pr_type: 'PR Type',
    item_category_material: 'Material',
    item_category_service: 'Service',
    plant: 'Plant',
    amount: 'Amount',
    unit_price: 'Unit Price',
    unit_of_measure: 'Unit of Measure',
    quantity: 'Quantity',
    po_number: 'PO Number',

    account_information: 'Account Information',
    asset_number: 'Asset Number',
    internal_order: 'Internal Order',
    billing_cost_center: 'Billing (Cost Center)',
    gl_account: 'GL Account',
    profit_center: 'Profit Center',
    split_accounting: 'Split Accounting',
    split_accounts_no: 'No',
    split_accounts_yes: 'Yes',

    dates: 'Dates',
    need_by: 'Need By',
    service_start: 'Service Start',
    service_end: 'Service End',

    approve_caption: 'APPROVE',
    reject_caption: 'REJECT',

    cancel_button_caption: 'CANCEL',
    close_button_caption: 'CLOSE',
    goto_overview_button_caption: 'GO TO OVERVIEW PAGE',

    approve_header_pattern: 'Approve {PrNumber}',
    approve_text:
      "Make sure you've carefully reviewed the purchase request for accuracy and completeness.",
    approve_comment_label: 'Comment',
    approve_comment_placeholder: 'Add comment ...',
    approve_confirm_header: 'Confirm approval',
    approve_confirm_text:
      'Are you sure you want to approve this purchase request? This action cannot be undone.',
    approve_success_header: 'Purchase request approved',
    approve_success_text_pattern: "You've successfully approved {PrNumber}.",
    approve_error_header: "Sorry, we've encountered an error.",
    approve_error_already_approved:
      'Probably someone has already taken action on this requisition.',
    approve_error_goto_overview: 'Please go to Overview page and start again from there.',
    approve_error_goto_ariba: 'Please go to Ariba and check the requisition data there.',

    reject_header_pattern: 'Reject {PrNumber}',
    reject_text:
      "Make sure you've carefully reviewed the purchase request for accuracy and completeness.",
    reject_comment_label: 'Comment',
    reject_comment_placeholder: 'Add comment ...',
    reject_confirm_header: 'Confirm rejection',
    reject_confirm_text:
      'Are you sure you want to reject this purchase request? This action cannot be undone.',
    reject_success_header: 'Purchase request rejected',
    reject_success_text_pattern: "You've rejected {PrNumber}.",
  },
} satisfies TranslationsSchema;
