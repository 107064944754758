import { TranslationsSchema } from './types';

export default {
  name: 'globalPagination',
  title: 'Global Pagination',
  sanityId: 'globalPagination',
  fields: {
    no_data: 'no data',
    results: 'results',
    of: 'of',
  },
} satisfies TranslationsSchema;
