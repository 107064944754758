import { TranslationsSchema } from './types';

export default {
  name: 'statusInvoice',
  title: 'Status Invoice Overview',
  sanityId: 'statusInvoice',
  fields: {
    page_header: 'Invoice status Overview',
    refiner_value: 'Value',
    amount: 'Amount:',
    refiner_days: 'Days',
    refiner_status: 'Status',
    refiner_supplier: 'Supplier',
    refiner_requester: 'Requester',
    refiner_preparer: 'Preparer',
    refiner_approver: 'Approver',
    refiner_invoice_date: 'Invoice Date',
    refiner_status_payment: 'Payment Status',
    status_payment: 'Payment Status:',
    status: 'Status:',
    invoice_date: 'Invoice Date:',
    company_code: 'Company Code:',
    open_link: 'Open PO vs. Invoice Value',
    status_definitions: 'Status Definitions',
    supplier: 'Supplier',
    requester: 'Requester',
    preparer: 'Preparer',
    approver: 'Approver',
    assigneduser: 'Assigned User',
    and: 'and',
  },
} satisfies TranslationsSchema;
