import { TranslationsSchema } from './types';

export default {
  name: 'statusPoOverview',
  title: 'Status PO Overview',
  sanityId: 'statusPoOverview',
  fields: {
    page_title: 'Purchase Order',
    refiner_value: 'Value',
    refiner_value_not_selected: 'not selected',
    refiner_requisition_date: 'Requisition Date',
    refiner_order_date: 'mm/dd/yyyy',
    refiner_today: 'Today',
    refiner_days: 'Days',
    refiner_status: 'Status',
    refiner_supplier: 'Supplier',
    refiner_requester: 'Requester',
    refiner_preparer: 'Preparer',
    result_amount: 'Amount',
    amount_invoices: 'Amount Invoiced (Posted)',
    result_requisition_date: 'Requisition Date',
    order_date: 'Order Date',
    status: 'Status',
    status_definitions: 'Status Definitions',
    // add other fields here
  },
} satisfies TranslationsSchema;
