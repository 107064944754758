'use client';
import { ReactNode, useContext, createContext, FC } from 'react';
import { Translations, defaultTranslations } from './schemas';

const TranslationsContext = createContext(defaultTranslations);
let currentTranslations: Translations = defaultTranslations;

interface TranslationsProviderProps {
  translations: Partial<Translations>;
  children: ReactNode;
}

/**
 * TranslationsProvider allows to access page tarnslations on any level component.
 * Each page updates translations in TranslationsContext during render (see _app.tsx).
 */
export const TranslationsProvider: FC<TranslationsProviderProps> = ({ children, translations }) => {
  //TODO: need to investigate translations mismatch in error handling
  if (translations) {
    currentTranslations = Object.assign({}, defaultTranslations, translations);
  } else {
    // translations are empty for client-side error (see _error.tsx).
    // But we need to show error page in the same locale as original page.
    // That's why we don't override currentTranslations.
  }

  return (
    <TranslationsContext.Provider value={currentTranslations}>
      {children}
    </TranslationsContext.Provider>
  );
};

export const useTranslations = () => useContext(TranslationsContext);
