import { TranslationsSchema } from './types';

export default {
  name: 'custom404ContractDetails',
  title: 'Status Contract Details 404',
  sanityId: 'custom404ContractDetails',
  fields: {
    title: 'Oops... Contract not found',
    message: 'Something went wrong and we didn’t find the contract you were looking for.',
    return_button: 'RETURN TO HOME PAGE',
  },
} satisfies TranslationsSchema;
