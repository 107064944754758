import { TranslationsSchema } from './types';

export default {
  name: 'custom404InvoiceDetails',
  title: 'Status Invoice Details 404',
  sanityId: 'custom404InvoiceDetails',
  fields: {
    title: 'Oops... Invoice not found',
    message:
      'Something went wrong and we didn’t find the invoice you were looking for. The record may be available in the source system.',
    invoice_track_button: 'GO TO INVOICETRACK',
    return_button: 'RETURN TO HOME PAGE',
  },
} satisfies TranslationsSchema;
