import { TranslationsSchema } from './types';

export default {
  name: 'statusPrOverview',
  title: 'Status PR Overview',
  sanityId: 'statusPrOverview',
  fields: {
    page_title: 'Purchase Requisitions Overview',
    refiner_value: 'Value',
    refiner_value_not_selected: 'not selected',
    refiner_requisition_date: 'Requisition Date',
    refiner_order_date: 'mm/dd/yyyy',
    refiner_today: 'Today',
    refiner_days: 'Days',
    refiner_status: 'Status',
    refiner_supplier: 'Supplier',
    refiner_requester: 'Requester',
    refiner_preparer: 'Preparer',

    link_status_definitions: 'Status Definitions',
    pending_plate_status: 'Pending',
    pending_plate_message:
      'Manual PR has been created. However, please allow a few minutes for it to be reflected in your PR status list.',

    result_amount: 'Amount',
    result_requisition_date: 'Requisition Date',
    result_status: 'Status',
    result_requester: 'Requester',
    result_preparer: 'Preparer',
  },
} satisfies TranslationsSchema;
