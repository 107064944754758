import { TranslationsSchema } from './types';

export default {
  name: 'globalBreadcrumb',
  title: 'Global Breadcrumb',
  sanityId: 'globalBreadcrumb',
  fields: {
    back_to_pr_overview: 'PR Overview',
    back_to_po_overview: 'PO Overview',
    back_to_invoice_overview: 'Invoice Overview',
    back_to_invoice_approvals_overview: 'Invoice Approvals Overview',
    back_to_contract_overview: 'Contract Requests Overview',
    back_to_pr_approvals_overview: 'PR Approvals Overview',
  },
} satisfies TranslationsSchema;
