import { TranslationsSchema } from './types';

export default {
  name: 'statusPoDetails',
  title: 'Status PO Details',
  sanityId: 'statusPoDetails',
  fields: {
    po_number: 'PO Number',
    amount: 'Amount',
    amount_invoiced_posted: 'Amount Invoiced (Posted)',
    supplier: 'Supplier',
    supplier_id: 'Supplier ID',
    order_date: 'Order Date',
    status: 'Status',
    status_definitions: 'Status Definitions',
    requester: 'Requester',
    preparer: 'Preparer',
    purchasing_org: 'Purchasing Org',
    company_code: 'Company Code',
    po_items: 'PO Items',
    po_line: 'PO Line',
    po_information: 'PO Information',
    po_type: 'PO Type',
    po_amount: 'Amount',
    line_status: 'Line Status',
    pr_number: 'PR Number',
    account_information: 'Account Information',
    asset_number: 'Asset Number',
    internal_order: 'Internal Order',
    billing_cost_center: 'Billing (Cost Center)',
    gl_account: 'GL Account',
    split_accounting: 'Split Accounting',
    yes: 'Yes',
    view_in_arriba: 'View in Ariba',
    no: 'No',
    dates: 'Dates',
    need_by: 'Need by',
    service_start: 'Service Start',
    service_end: 'Service End',
    bottom_view_in_arriba: 'VIEW IN ARIBA',
    purchase_order: 'Purchase Order',
    unknown: 'Unknown',
    no_data: 'no data',
    value: 'Value',
  },
} satisfies TranslationsSchema;
