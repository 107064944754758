/**
 * Translations used in all search pages globally.
 */
import { TranslationsSchema } from './types';

export default {
  name: 'globalSearch',
  title: 'Global Search',
  sanityId: 'globalSearch',
  fields: {
    filters_title: 'Filters',
    filters_button: 'Filter',
    show_filters_button: 'Show Filters',
    hide_filters_button: 'Hide Filters',
    searchbox_placeholder: 'Search',
    no_items_found: 'No items found',
    clear_all: 'Clear all',
    clear_filters: 'Clear filters',
  },
} satisfies TranslationsSchema;
